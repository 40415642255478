// function Footer() {
//   return (
//     <div className="">
//       <div className="">
//         <div>&nbsp;</div>
//         <div className="w-[88%] sm:w-[73%] mt-[76px] sm:mt-[162px] grid grid-cols-2 gap-y-[50px] sm:gap-0 sm:grid-cols-4 justify-between mx-auto">
//           <div className="">
//             <div className=" text-white font-bold text-[14px] sm:text-[16px]">
//               Solutions
//             </div>
//             <div className="text-white mt-2 sm:mt-4 gap-[3px] sm:gap-[6px] opacity-50 text-[14px] sm:text-[16px] flex flex-col">
//               <a href="/hoots">Hoots</a>
//               <a href="/kuwago">Kuwago</a>

//               <a href="/pique">Pique</a>
//               <a href="/owltalk">Owltalk</a>
//               <a href="/perch">Perch</a>
//             </div>
//           </div>

//           <div className="">
//             <div className=" text-white font-bold text-[14px] sm:text-[16px]">
//               Services
//             </div>
//             <div className="text-white mt-2 sm:mt-4 gap-[3px] sm:gap-[6px] opacity-50 text-[14px] sm:text-[16px] flex flex-col">
//               <a href="/generative-ai">Generative AI</a>
//               <a href="/analytics-and-ml">Machine Learning</a>
//               <a href="/data-platform">Data Platforms</a>
//               <a href="/customer-experience">Customer Engagement</a>
//             </div>
//           </div>

//           <div className="">
//             <div className=" text-white font-bold text-[14px] sm:text-[16px]">
//               About
//             </div>
//             <div className="text-white mt-2 sm:mt-4 gap-[3px] sm:gap-[6px] opacity-50 text-[14px] sm:text-[16px] flex flex-col">
//               <a href="/story">Company</a>
//               <a href="/story">Team</a>
//               <a href="/story">Careers</a>
//               <a href="/story">Blog</a>
//             </div>
//           </div>

//           <div className="">
//             <div className=" text-white font-bold text-[14px] sm:text-[16px]">
//               Contact
//             </div>
//             <div className="text-white mt-2 sm:mt-4 gap-[3px] sm:gap-[6px] opacity-50 text-[14px] sm:text-[16px] flex flex-col">
//               <a href="onebyzero.ai">Email</a>
//               <a href="/">Facebook</a>
//               <a href="/">Twitter</a>
//               <a href="/">Instagram</a>
//             </div>
//           </div>
//         </div>

//         <div className="mt-[93px] sm:mt-[186px] text-white opacity-50 text-center mb-1 sm:mb-2 text-[14px] sm:text-[16px]">
//           © 2023 OneByZero Pte.
//         </div>

//         <div className="invisible">&nsbp;</div>
//       </div>
//     </div>
//   );
// }

const Box = ({ title, dataList }) => {
  const list = dataList.map((item, index) => {
    return (
      <a href={item.link} key={index}>
        <div className="leading-[100%] capitalize text-[14px] md:text-[16px] tracking-[-0.35px] RoobertMedium opacity-50 text-[#FFF]">
          {item.text}
        </div>
      </a>
    );
  });

  return (
    <div className=" flex flex-col items-start shrink-0 gap-4">
      <div className="text-[14px] md:text-[16px] text-[#FFF] leading-[100%] tracking-[-0.35px] RoobertMedium">
        {title}
      </div>
      <div className=" text-[14px] md:text-[16px] flex flex-col items-start gap-[6px]">
        {list}
      </div>
    </div>
  );
};

function NewFooter() {
  return (
    <>
      <div>&nbsp;</div>
      <div className="w-[88%] md:w-[74%] mt-[76px] grid grid-cols-2 gap-y-[101px] md:gap-0  md:mt-[162px]  mx-auto md:flex justify-between items-start content-start flex-wrap">
        <Box
          title={"Services"}
          dataList={[
            { text: "Generative AI", link: "/generative-ai" },
            { text: "Machine Learning", link: "/analytics-and-ml" },
            { text: "MarTech+", link: "/digital-experience" },
            { text: "Data Platforms", link: "/data-platform" },
          ]}
        />
        <Box
          title={"Solutions"}
          dataList={[
            { text: "Kuwago", link: "/kuwago" },
            { text: "Chatter", link: "/chattr" },
            { text: "Hoots", link: "/hoots" },
            { text: "Perch", link: "/perch" },
            { text: "Pique", link: "/pique" },
          ]}
        />
        <Box
          title={"About"}
          dataList={[
            { text: "Company", link: "/story" },
            {
              text: "Careers",
              link: "https://careers.onebyzero.ai/jobs/Careers",
            },
            // { text: "Blog", link: "/story" },
          ]}
        />
        <Box
          title={"Contact"}
          dataList={[
            { text: "Email", link: "mailto:contact@onebyzero.ai" },
            { text: "Linkedin", link: "https://www.linkedin.com/company/1bz/" },
            { text: "Twitter", link: "https://twitter.com/1by0ai" },
          ]}
        />
      </div>

      <div className="mt-[93px] sm:mt-[186px]  text-white opacity-50 text-center mb-1 sm:mb-2 text-[14px] sm:text-[16px]">
        © 2023 ONEBYZERO
      </div>
      <div>&nbsp;</div>
    </>
  );
}

export default NewFooter;
