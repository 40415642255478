import Dropdown from "./dropdown";
import { useState, useRef } from "react";
import MobileLayout from "./mobile_layout";
import DesktopLayout from "./desktop_layout";

function MobileDropdown({ data }) {
  const list = data.map((item, index) => {
    return (
      <a href={item.link} key={index}>
        <div className="py-[10px] pl-[16px] pr-[60px] rounded-[6px]  hover:bg-[#FAF7F7] text-[#262626] text-start">
          <div className=" uppercase text-[14px] tracking-[0.7px] RoobertRegular ">
            {item.title}
          </div>
          <div className=" mt-[2px] opacity-80 leading-[120%] text-[13px]">
            {item.descritption}
          </div>
        </div>
      </a>
    );
  });

  return (
    <div className="w-[100%]">
      <div className=" p-3 flex flex-col gap-[10px]">{list}</div>
    </div>
  );
}

function ModalMobileLayout({ children, backgroundColor }) {
  const list = [0, 1].map((_, index) => {
    return (
      <div
        key={index}
        className={`border-r border-[#7B7B7B] ${
          backgroundColor === "black"
            ? "border-opacity-20"
            : "border-opacity-10"
        }  h-full w-full `}
      ></div>
    );
  });

  return (
    <div className="relative w-screen h-full max-h-full">
      <div
        className={` absolute object-cover top-0 left-0 right-0  w-screen h-full max-h-full ${
          backgroundColor === "black" ? "bg-[#000]" : "bg-[#FAF7F7]"
        }  px-[24px]`}
      >
        <div className="grid grid-cols-3 gap-1 w-full h-full">
          <div
            key={2}
            className={`border-r border-[#7B7B7B] ${
              backgroundColor === "black"
                ? "border-opacity-20"
                : "border-opacity-10"
            } h-full w-full border-l`}
          ></div>
          {list}
        </div>
      </div>
      <div className="relative w-screen h-full max-h-full">{children}</div>
    </div>
  );
}

function HeaderModal({ setState }) {
  const solutionData = [
    {
      title: "kuwago",
      descritption: "Enterprise search & question-answering that just works.",
      link: "/kuwago",
    },
    {
      title: "Chatter",
      descritption: "Generative-AI powered chatbot engine.",
      link: "/chattr",
    },
    {
      title: "Hoots",
      descritption: "Omni-channel communication at scale with customers.",
      link: "/hoots",
    },
    {
      title: "perch",
      descritption: "Industry-Specific Customer Data Models & Platform",
      link: "/perch",
    },
    {
      title: "pique",
      descritption: "Hyper-personalized customer engagement at scale",
      link: "/pique",
    },
  ];

  const serviceData = [
    {
      title: "Generative AI",
      descritption: "Pick, train, fine-tune & deploy LLMs with ROI",
      link: "/generative-ai",
    },
    {
      title: "Machine Learning",
      descritption: "Define & Deploy ML Strategy, Models & MLOps",
      link: "/analytics-and-ml",
    },
    {
      title: "MARTECH+",
      descritption: "Set up Omni-channel personalized customer experiences",
      link: "/digital-experience",
    },
    {
      title: "Data Platforms",
      descritption: "Bring up a Modern data stack with governance",
      link: "/data-platform",
    },
  ];

  const [serviceDropdown, setServiceDropdown] = useState(false);
  const [solutionDropdown, setSolutionDropdown] = useState(false);

  return (
    <div className="">
      <div>
        {/* <!-- Main modal --> */}
        <div className="fixed top-0 left-0 right-0 z-30  w-screen overflow-x-hidden overflow-y-auto  h-full max-h-full">
          <div className="w-screen overflow-x-hidden overflow-y-auto  h-full max-h-full">
            <div className="w-screen overflow-x-hidden overflow-y-auto  h-full max-h-full bg-[#FAF7F7]">
              <ModalMobileLayout>
                <div className="w-screen overflow-x-hidden overflow-y-auto  h-full max-h-full text-[#000]">
                  <div className="h-0">&nbsp;</div>
                  <div className="mt-[16px] justify-between flex w-[90%] mx-auto gap-[0.6%] h-12">
                    <a href="/" className="w-full md:w-[147px]   h-full">
                      <button
                        className="flex w-full md:w-full items-center h-full justify-center rounded-xl py-[6px] pr-[12px] pl-[16px] bg-white font-bold text-[13px]"
                        style={{
                          boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.07)",
                          backdropFilter: "blur(15.151522636413574px)",
                        }}
                      >
                        <div className="flex md:mx-auto mr-auto">
                          {" "}
                          <div className="flex items-center">
                            {/* <div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="22"
                                height="23"
                                viewBox="0 0 22 23"
                                fill="none"
                              >
                                <circle
                                  cx="11"
                                  cy="11.5"
                                  r="11"
                                  fill="#2E3041"
                                />
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M15.6223 15.7562C15.8734 15.1499 16.0027 14.5 16.0027 13.8438H11.0053H6.00781C6.00781 14.5 6.13708 15.1499 6.38822 15.7562C6.63936 16.3625 7.00747 16.9134 7.47153 17.3775C7.93558 17.8415 8.4865 18.2096 9.09282 18.4608C9.69913 18.7119 10.349 18.8412 11.0053 18.8412C11.6615 18.8412 12.3114 18.7119 12.9177 18.4608C13.524 18.2096 14.0749 17.8415 14.539 17.3775C15.003 16.9134 15.3711 16.3625 15.6223 15.7562ZM13.1719 14.7412C13.2898 14.4567 13.3504 14.1517 13.3504 13.8438H11.0053L8.6601 13.8438C8.6601 14.1517 8.72076 14.4567 8.83861 14.7412C8.95647 15.0257 9.12921 15.2843 9.34698 15.502C9.56475 15.7198 9.82328 15.8926 10.1078 16.0104C10.3923 16.1283 10.6973 16.1889 11.0053 16.1889C11.3132 16.1889 11.6182 16.1283 11.9027 16.0104C12.1873 15.8926 12.4458 15.7198 12.6635 15.502C12.8813 15.2843 13.0541 15.0257 13.1719 14.7412Z"
                                  fill="white"
                                />
                                <rect
                                  x="6.00781"
                                  y="10.0459"
                                  width="9.99488"
                                  height="2.34517"
                                  fill="white"
                                />
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M12.1233 6.41417V4.18066H9.88975H7.65625V6.41417H9.88975V8.64767H12.1233L12.1233 6.41417Z"
                                  fill="white"
                                />
                              </svg>
                            </div> */}
                            <div
                              className="ObviouslyDemoRegular font-[540] tracking-[0.13px]"
                              style={{ color: "rgba(33, 35, 53, 0.94)" }}
                            >
                              ONEBYZERO
                            </div>
                          </div>
                        </div>
                      </button>
                    </a>

                    <button
                      className=" bg-white py-[6px] ml-2 justify-self-end px-4 rounded-[12px]"
                      onClick={() => setState(false)}
                      style={{
                        boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.07)",
                        backdropFilter: "blur(15.151522636413574px)",
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="15"
                        viewBox="0 0 15 15"
                        fill="none"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M12.8536 2.85355C13.0488 2.65829 13.0488 2.34171 12.8536 2.14645C12.6583 1.95118 12.3417 1.95118 12.1464 2.14645L7.5 6.79289L2.85355 2.14645C2.65829 1.95118 2.34171 1.95118 2.14645 2.14645C1.95118 2.34171 1.95118 2.65829 2.14645 2.85355L6.79289 7.5L2.14645 12.1464C1.95118 12.3417 1.95118 12.6583 2.14645 12.8536C2.34171 13.0488 2.65829 13.0488 2.85355 12.8536L7.5 8.20711L12.1464 12.8536C12.3417 13.0488 12.6583 13.0488 12.8536 12.8536C13.0488 12.6583 13.0488 12.3417 12.8536 12.1464L8.20711 7.5L12.8536 2.85355Z"
                          fill="black"
                        />
                      </svg>
                    </button>
                  </div>

                  <div className="RoobertLight ml-[7%] flex flex-col gap-[48px] mt-[54px] mb-[200px]">
                    <button className="h-full text-left RoobertLight">
                      <a
                        onClick={() => {
                          // condition for closing other dropdown
                          if (solutionDropdown && !serviceDropdown) {
                            setSolutionDropdown(false);
                          }
                          setServiceDropdown(!serviceDropdown);
                        }}
                        className={`${
                          serviceDropdown ? "underline" : ""
                        } tracking-[1px] RoobertLight`}
                        style={{ textUnderlineOffset: "5px" }}
                      >
                        SERVICES{" "}
                      </a>

                      {serviceDropdown ? (
                        <MobileDropdown data={serviceData} />
                      ) : null}
                    </button>

                    <button className="h-full text-left">
                      <a
                        onClick={() => {
                          setSolutionDropdown(!solutionDropdown);
                          if (serviceDropdown && !solutionDropdown) {
                            setServiceDropdown(false);
                          }
                          setSolutionDropdown(!solutionDropdown);
                        }}
                        className={` uppercase RoobertLight tracking-[1px]  ${
                          solutionDropdown ? "underline" : ""
                        }`}
                        style={{ textUnderlineOffset: "5px" }}
                      >
                        Solutions{" "}
                      </a>

                      {solutionDropdown ? (
                        <MobileDropdown data={solutionData} />
                      ) : null}
                    </button>

                    <a href="/customer">
                      <div className=" uppercase RoobertLight tracking-[1px]">
                        CUSTOMERS
                      </div>
                    </a>

                    <a href="/story">
                      <div className=" uppercase RoobertLight tracking-[1px]">
                        About
                      </div>
                    </a>
                  </div>

                  <a href="/contact-us" className="w-[15%]">
                    <button
                      className=" items-center group ml-[7%] justify-center focus:bg-[#000] bg-white px-[16px] py-[11px] flex gap-[10px] rounded-[12px]"
                      style={{
                        boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.07)",
                      }}
                    >
                      <p className="RoobertRegular group-hover:underline group-hover:underline-offset-[5px] group-hover:text-[#FFF] text-[#1F1F1F]">
                        CONTACT
                      </p>
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="17"
                          height="17"
                          viewBox="0 0 17 17"
                          fill="none"
                          className="group-hover:hidden"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M4.1816 12.8202C3.98634 12.625 3.98634 12.3084 4.1816 12.1131L11.8281 4.46667L6.80182 4.46668C6.52568 4.46668 6.30182 4.24282 6.30182 3.96668C6.30182 3.69053 6.52568 3.46668 6.80182 3.46668L13.0352 3.46667C13.1678 3.46667 13.2949 3.51935 13.3887 3.61312C13.4825 3.70689 13.5352 3.83407 13.5352 3.96667L13.5352 10.2C13.5352 10.4762 13.3113 10.7 13.0352 10.7C12.759 10.7 12.5352 10.4762 12.5352 10.2V5.17378L4.88871 12.8202C4.69345 13.0155 4.37687 13.0155 4.1816 12.8202Z"
                            fill="black"
                          />
                        </svg>

                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="17"
                          height="17"
                          viewBox="0 0 17 17"
                          fill="none"
                          className="hidden group-hover:block"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M4.1777 12.8204C3.98243 12.6251 3.98243 12.3085 4.1777 12.1133L11.8241 4.4668L6.79792 4.4668C6.52178 4.4668 6.29792 4.24294 6.29792 3.9668C6.29792 3.69065 6.52178 3.4668 6.79792 3.4668L13.0313 3.4668C13.1639 3.4668 13.291 3.51948 13.3848 3.61324C13.4786 3.70701 13.5312 3.83419 13.5313 3.9668L13.5312 10.2001C13.5312 10.4763 13.3074 10.7001 13.0312 10.7001C12.7551 10.7001 12.5312 10.4763 12.5312 10.2001V5.17391L4.8848 12.8204C4.68954 13.0156 4.37296 13.0156 4.1777 12.8204Z"
                            fill="white"
                          />
                        </svg>
                      </div>
                    </button>
                  </a>
                  <div>&nbsp;</div>
                </div>
              </ModalMobileLayout>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function Header() {
  const solutionData = [
    {
      title: "kuwago",
      descritption: "Enterprise search & question-answering that just works",
      link: "/kuwago",
    },
    {
      title: "Chatter",
      descritption: "Generative-AI powered chatbot engine",
      link: "/chattr",
    },
    {
      title: "Hoots",
      descritption: "Omni-channel communication at scale with customers",
      link: "/hoots",
    },
    {
      title: "perch",
      descritption: "Industry-specific customer data models and platform",
      link: "/perch",
    },
    {
      title: "pique",
      descritption: "Hyper-personalized customer engagement at scale",
      link: "/pique",
    },
  ];

  const serviceData = [
    {
      title: "Generative AI",
      descritption: "Pick, train, fine-tune & deploy LLMs with ROI",
      link: "/generative-ai",
    },
    {
      title: "Machine Learning",
      descritption: "Define & Deploy ML Strategy, Models & MLOps",
      link: "/analytics-and-ml",
    },
    {
      title: "MARTECH+",
      descritption: "Set up Omni-channel personalized customer experiences",
      link: "/digital-experience",
    },
    {
      title: "Data Platforms",
      descritption: "Bring up a Modern data stack with governance",
      link: "/data-platform",
    },
  ];

  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <div className="h-0">&nbsp;</div>
      <div className=" justify-center flex mt-4">
        <div className="w-[95%] md:w-[80%] mx-auto fixed z-[1]">
          <div className=" flex justify-between md:flex w-full gap-[0.6%] h-[43px]">
            <a href="/" className="w-full md:w-[147px]   h-full">
              <button
                className="flex w-full md:w-full items-center h-full justify-center rounded-xl pt-[6px] pb-[9px] pr-[12px] pl-[16px] bg-white font-bold text-[13px]"
                style={{
                  boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.07)",
                  backdropFilter: "blur(15.151522636413574px)",
                }}
              >
                <div className="flex md:mx-auto mr-auto">
                  {" "}
                  <div className="flex items-center">
                    {/* <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="22"
                        height="23"
                        viewBox="0 0 22 23"
                        fill="none"
                      >
                        <circle cx="11" cy="11.5" r="11" fill="#2E3041" />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M15.6223 15.7562C15.8734 15.1499 16.0027 14.5 16.0027 13.8438H11.0053H6.00781C6.00781 14.5 6.13708 15.1499 6.38822 15.7562C6.63936 16.3625 7.00747 16.9134 7.47153 17.3775C7.93558 17.8415 8.4865 18.2096 9.09282 18.4608C9.69913 18.7119 10.349 18.8412 11.0053 18.8412C11.6615 18.8412 12.3114 18.7119 12.9177 18.4608C13.524 18.2096 14.0749 17.8415 14.539 17.3775C15.003 16.9134 15.3711 16.3625 15.6223 15.7562ZM13.1719 14.7412C13.2898 14.4567 13.3504 14.1517 13.3504 13.8438H11.0053L8.6601 13.8438C8.6601 14.1517 8.72076 14.4567 8.83861 14.7412C8.95647 15.0257 9.12921 15.2843 9.34698 15.502C9.56475 15.7198 9.82328 15.8926 10.1078 16.0104C10.3923 16.1283 10.6973 16.1889 11.0053 16.1889C11.3132 16.1889 11.6182 16.1283 11.9027 16.0104C12.1873 15.8926 12.4458 15.7198 12.6635 15.502C12.8813 15.2843 13.0541 15.0257 13.1719 14.7412Z"
                          fill="white"
                        />
                        <rect
                          x="6.00781"
                          y="10.0459"
                          width="9.99488"
                          height="2.34517"
                          fill="white"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M12.1233 6.41417V4.18066H9.88975H7.65625V6.41417H9.88975V8.64767H12.1233L12.1233 6.41417Z"
                          fill="white"
                        />
                      </svg>
                    </div> */}
                    <div
                      className=" ObviouslyDemoRegular font-[540] tracking-[0.13px]"
                      style={{ color: "rgba(33, 35, 53, 0.94)" }}
                    >
                      ONEBYZERO
                    </div>
                  </div>
                </div>
              </button>
            </a>
            <div
              className="RoobertLight w-full h-full hidden md:flex relative items-center bg-[#FDFDFD] rounded-lg sm:rounded-xl  p-[3px] sm:p-[0px] text-[13px] text-black"
              style={{
                boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.07)",
                backdropFilter: "blur(15.151522636413574px)",
              }}
            >
              <div className="w-full flex ml-[33px] h-full">
                <button className=" h-full group relative">
                  <a
                    className="group-hover:underline hover:underline text-[13px] tracking-[0.845px]"
                    style={{ textUnderlineOffset: "5px" }}
                  >
                    SERVICES{" "}
                  </a>

                  <div
                    className={`z-10 hidden group-hover:block hover:block  absolute left-[-32px] w-[317px]`}
                  >
                    <Dropdown data={serviceData} />
                  </div>
                </button>
                <button className="ml-[4%] group relative h-full">
                  <a
                    className=" group-hover:underline tracking-[0.845px]"
                    style={{ textUnderlineOffset: "5px" }}
                  >
                    SOLUTIONS
                  </a>
                  <div></div>
                  <div
                    className={`z-10 hidden group-hover:block hover:block absolute left-[0px]  w-[317px]`}
                  >
                    <Dropdown data={solutionData} />
                  </div>
                </button>
                <button className="ml-[4%]">
                  <a href="/customer" className="tracking-[0.845px]">
                    CUSTOMERS
                  </a>
                </button>
                <button className="ml-[4%]">
                  <a href="/story" className="tracking-[0.845px]">
                    ABOUT
                  </a>
                </button>
              </div>
            </div>
            <a href="/contact-us" className=" hidden md:flex">
              <button
                className=" items-center justify-center group focus:bg-[#000] bg-white px-[16px] py-[11px] flex gap-[10px] rounded-[12px]"
                style={{
                  boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.07)",
                }}
              >
                <p className="RoobertRegular group-hover:underline group-hover:underline-offset-[5px] group-focus:text-[#FFF] text-[#1F1F1F] text-[14px]">
                  CONTACT 
                </p>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    fill="none"
                    className="group-focus:hidden"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M4.1816 12.8202C3.98634 12.625 3.98634 12.3084 4.1816 12.1131L11.8281 4.46667L6.80182 4.46668C6.52568 4.46668 6.30182 4.24282 6.30182 3.96668C6.30182 3.69053 6.52568 3.46668 6.80182 3.46668L13.0352 3.46667C13.1678 3.46667 13.2949 3.51935 13.3887 3.61312C13.4825 3.70689 13.5352 3.83407 13.5352 3.96667L13.5352 10.2C13.5352 10.4762 13.3113 10.7 13.0352 10.7C12.759 10.7 12.5352 10.4762 12.5352 10.2V5.17378L4.88871 12.8202C4.69345 13.0155 4.37687 13.0155 4.1816 12.8202Z"
                      fill="black"
                    />
                  </svg>

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    fill="none"
                    className="hidden group-focus:block"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M4.1777 12.8204C3.98243 12.6251 3.98243 12.3085 4.1777 12.1133L11.8241 4.4668L6.79792 4.4668C6.52178 4.4668 6.29792 4.24294 6.29792 3.9668C6.29792 3.69065 6.52178 3.4668 6.79792 3.4668L13.0313 3.4668C13.1639 3.4668 13.291 3.51948 13.3848 3.61324C13.4786 3.70701 13.5312 3.83419 13.5313 3.9668L13.5312 10.2001C13.5312 10.4763 13.3074 10.7001 13.0312 10.7001C12.7551 10.7001 12.5312 10.4763 12.5312 10.2001V5.17391L4.8848 12.8204C4.68954 13.0156 4.37296 13.0156 4.1777 12.8204Z"
                      fill="white"
                    />
                  </svg>
                </div>
              </button>
            </a>
            <button
              className="md:hidden ml-2 bg-white py-[6px] justify-self-end px-4 rounded-[12px]"
              style={{
                boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.07)",
                backdropFilter: "blur(15.151522636413574px)",
              }}
              onClick={() => setIsOpen(true)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="16"
                viewBox="0 0 15 16"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M1.5 3.5C1.22386 3.5 1 3.72386 1 4C1 4.27614 1.22386 4.5 1.5 4.5H13.5C13.7761 4.5 14 4.27614 14 4C14 3.72386 13.7761 3.5 13.5 3.5H1.5ZM1 8C1 7.72386 1.22386 7.5 1.5 7.5H13.5C13.7761 7.5 14 7.72386 14 8C14 8.27614 13.7761 8.5 13.5 8.5H1.5C1.22386 8.5 1 8.27614 1 8ZM1 12C1 11.7239 1.22386 11.5 1.5 11.5H13.5C13.7761 11.5 14 11.7239 14 12C14 12.2761 13.7761 12.5 13.5 12.5H1.5C1.22386 12.5 1 12.2761 1 12Z"
                  fill="black"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
      {isOpen && <HeaderModal setState={setIsOpen} />}
    </div>
  );
}

export default Header;
