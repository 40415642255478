import Contact from "./shared/contact";
import Header from "./shared/header";
import Footer from "./shared/footer";
import DesktopLayout from "./shared/desktop_layout";
import MobileLayout from "./shared/mobile_layout";
import { useState, useEffect } from "react";
import Marquee from "react-marquee-slider";
import Card from "./shared/customer/Card";
import SmallCard from "./shared/customer/SmallCard";
import FilteredButton from "./shared/customer/FilteredButton";
import CardBody from "./shared/customer/CardBody";
import CustomerModal from "./shared/customer/CustomerModal";

const smallCardData = [
  {
    title: "Multi-objective optimization models",
    heading: "400%",
    description:
      "Significant improvement in monthly passenger misconnection rate ",
    img_web: "images/customer-page/logos/sats_web.webp",
    tag: "MACHINE LEARNING",
    company: "Sats",
    modalIndex: 9,
  },
  {
    title: "AI-driven campaign management",
    heading: "400%",
    description: "improvement in take-up rate for customized offers",
    img_web: "images/customer-page/logos/celcom_web.png",
    tag: "MACHINE LEARNING",
    company: "Celcom",
    modalIndex: 0,
  },
  {
    title: "Accelerated Marketing Data & Revenue Insights",
    heading: "75%",
    description: "Increased business users’ productivity",
    img_web: "images/customer-page/logos/xl_web.png",
    tag: "Generative AI",
    company: "XL",
    modalIndex: 1,
  },
  {
    title: "Predictive Modelling & Analytics",
    heading: "$10M",
    description: "Estimated annual savings from reduced load to call centres",
    img_web: "images/customer-page/logos/verizon_web.png",
    tag: "DIGITAL EXPERIENCE",
    company: "Verizon",
    modalIndex: 2,
  },
  {
    title: "Accelerated Discovery of Insights",
    heading: "1 week to a few hours",
    description:
      "reduced periodic data-related planning and strategy tasks of product and marketing team",
    img_web: "images/customer-page/logos/xl_web.png",
    tag: "MACHINE LEARNING",
    company: "XL",
    modalIndex: 1,
  },
  {
    title: "Enhanced DIGITAL EXPERIENCE",
    heading: "90%",
    description: "accuracy while engaging in over 15k conversations per month",
    img_web: "images/customer-page/logos/union_bank_web.png",
    tag: "MACHINE LEARNING",
    company: "Union Bank",
    modalIndex: 3,
  },
  {
    title: "Data Modelling and Hybrid Deployment",
    heading: "10x",
    description:
      "improvement in campaign efficiency over hard-coded offers, compared to control group",
    img_web: "images/customer-page/logos/pldt_web.png",
    tag: "Data Platorms",
    company: "PLDT",
    modalIndex: 4,
  },
  // {
  //   title: "AI Strategy for Splore",
  //   heading: "30%",
  //   description:
  //     "over revenue targets achieved in the first quarter, following the implementation of our strategy",
  //   img_web: "images/customer-page/logos/splore_web.png",
  //   tag: "DIGITAL EXPERIENCE",
  //   company: "Splore",
  //   modalIndex: 5,
  // },
  {
    title: "Data and AI Strategy",
    heading: "30%",
    description:
      "over revenue targets achieved in the first quarter, following the implementation of our strategy",
    img_web: "images/customer-page/logos/pldt_web.png",
    tag: "MACHINE LEARNING",
    company: "PLDT",
    modalIndex: 4,
  },
  {
    title: "Infrastructure Migration",
    heading: "20%",
    description: "reduction in infrastructure cost",
    img_web: "images/customer-page/logos/sinarams_web.png",
    tag: "Data Platorms",
    company: "Sinarms",
    modalIndex: 6,
  },
  // {
  //   title: "Data Architecture for a Gaming Company",
  //   heading: "5x",
  //   description: "increase in number of active users",
  //   img_web: "images/customer-page/logos/splore_web.png",
  //   tag: "Generative AI",
  //   company: "Gaming Company",
  //   modalIndex: 7,
  // },
  {
    title: "Visualization and Orchestration of Data",
    heading: "45%",
    description: "reduction in data storage cost",
    img_web: "images/customer-page/logos/paytm_web.png",
    tag: "DIGITAL EXPERIENCE",
    company: "Paytm",
    modalIndex: 8,
  },
];

const modalData = [
  {
    company: "Celcom",
    imgBackground: "light-blue",
    img: "images/customer-page/logos/celcom_web.png",
    title: "AI-driven campaign management",
    tag: "MACHINE LEARNING",
    description:
      "Celcom required a campaign management system that can identify the right offer for the right customer at the right time through the right channel",
    inventionList: [
      {
        description:
          "Deployed an autonomous campaign management system that automatically created and experimented with multiple campaigns",
      },
      {
        description:
          "Used active learning, hierarchical clustering and multi-armed bandit optimization to come up with the right campaign strategy to optimize take-up rates",
      },
    ],
    numberList: [
      {
        num: "",
        description:
          "Over 400% improvement in take-up rate for customized offers",
      },
      {
        num: "",
        description:
          "Thousands of micro segments identified automatically for reactivation and upsell campaigns",
      },
    ],
  },

  {
    company: "XL",
    imgBackground: "light-blue",
    img: "images/customer-page/logos/xl_web.png",
    title: "Accelerated Marketing Data & Revenue Insights",
    tag: "MACHINE LEARNING",
    description:
      "XL Axiata suffered from a lack of deep understanding of target customers, dependence on IT & Analytics teams and delay in business decisions",
    inventionList: [
      {
        description:
          "Personalised understanding of target customers for marketing teams",
      },
      {
        description:
          "Ability to deep dive on demographics, segments, product behaviours, usage trends, etc",
      },
      {
        description:
          "Enable business users & accelerate marketing & revenue insights",
      },
    ],
    numberList: [
      {
        num: "",
        description:
          "Increased business users’ productivity in terms of time to generate reports",
      },
      {
        num: "",
        description:
          "Reduced dependency on IT & Analytics teams in terms of number of service requests",
      },
    ],
  },

  {
    company: "Verizon",
    imgBackground: "light-red",
    img: "images/customer-page/logos/verizon_web.png",
    title: "Predictive Modelling & Analytics",
    tag: "MACHINE LEARNING",
    description:
      "Verizon faced high load on call centre, increased costs, lack of visibility on customer behaviour and top issues they were facing",
    inventionList: [
      {
        description:
          "High load on call centre | Increased costs | Lack of visibility on customer behaviour and top issues they were facing",
      },
      {
        description:
          "High load on call centre | Increased costs | Lack of visibility on customer behaviour and top issues they were facing",
      },
      {
        description:
          "Real-time prediction updates & model scoring based on customer behaviour",
      },
    ],
    numberList: [
      {
        num: "",
        description:
          "Millions of dollars of estimated annual savings from reduced load to call centers",
      },
      {
        num: "",
        description: "4x Lift in ability to predict reasons for calls",
      },
      {
        num: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="41"
            height="41"
            viewBox="0 0 41 41"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M20.5005 2.39209C15.0283 2.39209 10.5922 6.8282 10.5922 12.3004C10.5922 16.8174 13.6147 20.6284 17.7475 21.8213C14.4831 22.2188 11.6831 23.3732 9.57398 25.4518C6.89008 28.0969 5.53125 32.0346 5.53125 37.1731C5.53125 37.8902 6.11253 38.4714 6.82958 38.4714C7.54663 38.4714 8.12792 37.8902 8.12792 37.1731C8.12792 32.4718 9.36566 29.3029 11.3967 27.3013C13.4315 25.2959 16.4693 24.2582 20.4961 24.2582C24.5229 24.2582 27.5607 25.2959 29.5956 27.3013C31.6267 29.3029 32.8645 32.4718 32.8644 37.1731C32.8644 37.8901 33.4457 38.4714 34.1627 38.4714C34.8798 38.4715 35.4611 37.8902 35.4611 37.1731C35.4612 32.0346 34.1023 28.0968 31.4183 25.4518C29.3104 23.3745 26.5126 22.2203 23.2509 21.8221C27.3851 20.63 30.4089 16.8183 30.4089 12.3004C30.4089 6.8282 25.9727 2.39209 20.5005 2.39209ZM13.1889 12.3004C13.1889 8.2623 16.4624 4.98876 20.5005 4.98876C24.5386 4.98876 27.8122 8.2623 27.8122 12.3004C27.8122 16.3385 24.5386 19.6121 20.5005 19.6121C16.4624 19.6121 13.1889 16.3385 13.1889 12.3004Z"
              fill="black"
            />
          </svg>
        ),
        description: "Improved customer satisfaction & better NPS scores",
      },
      {
        num: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="41"
            height="41"
            viewBox="0 0 41 41"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M20.5005 2.39209C15.0283 2.39209 10.5922 6.8282 10.5922 12.3004C10.5922 16.8174 13.6147 20.6284 17.7475 21.8213C14.4831 22.2188 11.6831 23.3732 9.57398 25.4518C6.89008 28.0969 5.53125 32.0346 5.53125 37.1731C5.53125 37.8902 6.11253 38.4714 6.82958 38.4714C7.54663 38.4714 8.12792 37.8902 8.12792 37.1731C8.12792 32.4718 9.36566 29.3029 11.3967 27.3013C13.4315 25.2959 16.4693 24.2582 20.4961 24.2582C24.5229 24.2582 27.5607 25.2959 29.5956 27.3013C31.6267 29.3029 32.8645 32.4718 32.8644 37.1731C32.8644 37.8901 33.4457 38.4714 34.1627 38.4714C34.8798 38.4715 35.4611 37.8902 35.4611 37.1731C35.4612 32.0346 34.1023 28.0968 31.4183 25.4518C29.3104 23.3745 26.5126 22.2203 23.2509 21.8221C27.3851 20.63 30.4089 16.8183 30.4089 12.3004C30.4089 6.8282 25.9727 2.39209 20.5005 2.39209ZM13.1889 12.3004C13.1889 8.2623 16.4624 4.98876 20.5005 4.98876C24.5386 4.98876 27.8122 8.2623 27.8122 12.3004C27.8122 16.3385 24.5386 19.6121 20.5005 19.6121C16.4624 19.6121 13.1889 16.3385 13.1889 12.3004Z"
              fill="black"
            />
          </svg>
        ),
        description: "Reduced waiting time in queue for a call centre agent",
      },
    ],
  },

  {
    company: "Union Bank",
    imgBackground: "light-red",
    img: "images/customer-page/logos/union_bank_web.png",
    title: "Enhanced DIGITAL EXPERIENCE",
    tag: "MACHINE LEARNING",
    description:
      "Customers experienced long wait times to talk to a Unionbank representative for simple requests such as credit card application, ATM locations, transaction disputes etc. and high customer volume led to high call centre operating costs.",
    inventionList: [
      {
        description:
          "Deployed a “digital employee” that engaged with customers via a chatbot for services including credit card applications, loans, ATM & branch locations via Facebook Messenger",
      },
    ],
    numberList: [
      {
        num: "",
        description:
          "“We received the platform on time and within budget but also enabled us to think outside the box in terms of the capabilities and services that could transform our business. In short, they are the definition of an innovative tech partner.”",
      },
      {
        num: "90%",
        description:
          "accuracy while engaging in over 15k conversations per month",
      },
      {
        num: "",
        description:
          "Rafael M. Aboitiz Digital Engagement Head, Union Bank of the Philippines",
      },
    ],
  },

  {
    company: "PLDT",
    imgBackground: "light-red",
    img: "images/customer-page/logos/pldt_web.png",
    title: "Data and AI Strategy",
    tag: "MACHINE LEARNING",
    description:
      "PLDT ran manual and gut-driven campaign designs with limited exploration and data-driven optimization of campaigns and lack of personalization of offers",
    inventionList: [
      {
        description:
          "Deployed an NBO framework consisting of multiple propensity, persuadability and revenue optimization models",
      },
      {
        description:
          "Deployed on a combination of on-prem and AWS infrastructure",
      },
    ],
    numberList: [
      {
        num: "",
        description:
          "improvement in campaign efficiency over hard-coded offers, compared to control group",
      },
    ],
  },

  {
    company: "Splore",
    imgBackground: "light-red",
    img: "images/customer-page/logos/splore_web.png",
    title: "AI Strategy for a Gaming Company",
    tag: "MACHINE LEARNING",
    description:
      "Difficulty in serving relevant results for gaming search queries | Complex, ever-changing vocabulary | Multiple heterogeneous data sources | Challenges in authority, trust, conflicting opinions",
    inventionList: [
      {
        description:
          "Deployed NLP and Information Retrieval models for the following to optimize results of queries on domain-specific and proprietary datasets:- named entity recognition- query classification- semantic search- question-answering models",
      },
      {
        description: "Used LLMs for rapid deployment and iteration",
      },
    ],
    numberList: [
      {
        num: "40%",
        description:
          "increase in accuracy of results shown in 8 weeks, resulting in better user satisfaction and engagement",
      },
    ],
  },

  {
    company: "Sinarms",
    imgBackground: "light-red",
    img: "images/customer-page/logos/sinarams_web.png",
    title: "Infrastructure Migration",
    tag: "MACHINE LEARNING",
    description:
      "On-Prem Infra | Monolithic architecture | Manual deployments | Running old versions of SQL Server | PHP based codebase with vulnerabilities",
    inventionList: [
      {
        description: "Trading infrastructure migration with 24 applications to",
      },
      {
        description: "Implemented to replace unreliable local email server",
      },
      {
        description: "Support company-wide storage requirements with",
      },
      {
        description:
          "Cloud calling to replace PABX infrastructure across all branches",
      },
    ],
    numberList: [
      {
        num: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="42"
            height="41"
            viewBox="0 0 42 41"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M21.0005 2.39062C15.5283 2.39062 11.0922 6.82673 11.0922 12.299C11.0922 16.8183 14.1179 20.6309 18.254 21.8217C14.9868 22.2185 12.1845 23.373 10.074 25.453C7.39008 28.0981 6.03125 32.0358 6.03125 37.1743C6.03125 37.8914 6.61253 38.4727 7.32958 38.4727C8.04663 38.4727 8.62792 37.8914 8.62792 37.1743C8.62792 32.473 9.86566 29.3041 11.8967 27.3025C13.9315 25.2971 16.9693 24.2594 20.9961 24.2594C25.0229 24.2594 28.0607 25.2971 30.0956 27.3025C32.1267 29.3041 33.3645 32.473 33.3644 37.1743C33.3644 37.8913 33.9457 38.4726 34.6627 38.4727C35.3798 38.4727 35.9611 37.8914 35.9611 37.1743C35.9612 32.0358 34.6023 28.0981 31.9183 25.453C29.809 23.3743 27.0089 22.2199 23.7444 21.8225C27.8819 20.6326 30.9089 16.8193 30.9089 12.299C30.9089 6.82673 26.4727 2.39062 21.0005 2.39062ZM13.6889 12.299C13.6889 8.26083 16.9624 4.98729 21.0005 4.98729C25.0386 4.98729 28.3122 8.26083 28.3122 12.299C28.3122 16.3371 25.0386 19.6106 21.0005 19.6106C16.9624 19.6106 13.6889 16.3371 13.6889 12.299Z"
              fill="black"
            />
          </svg>
        ),
        description: "Certification gained within 3 months",
      },

      {
        num: "20%",
        description: "reduction in infrastructure cost",
      },

      {
        num: "18%",
        description: "improvement in system availability",
      },

      {
        num: "6x",
        description: "improvement in deployment frequency",
      },
    ],
  },

  {
    company: "Gaming Company",
    imgBackground: "light-red",
    img: "images/customer-page/logos/splore_web.png",
    title: "Data Architecture for a Gaming Company",
    tag: "MACHINE LEARNING",
    description:
      "Required a data architecture for handling vast amounts of internal data as well as external crawled data from chat &, social media platforms and the public web",
    inventionList: [
      {
        description:
          "Created a data strategy that allowed:- Integrating disparate data sources into a single data lake with different zones for different use-cases- Created a unique “universal” data schema that allowed ",
      },
      {
        description:
          "Created an AI strategy and roadmap that included:- Training and deploying a variety of NLP, Learning-to-rank and recommendation models- Fine tuning LLMs for specific domains",
      },
    ],
    numberList: [
      {
        num: "5x",
        description: "increase in number of active users",
      },

      {
        num: "40%",
        description: "increase in accuracy of results shown",
      },
    ],
  },

  {
    company: "Paytm",
    imgBackground: "light-red",
    img: "images/customer-page/logos/paytm_web.png",
    title: "Visualization and Orchestration of Data",
    tag: "MACHINE LEARNING",
    description:
      "On-Prem Infra | Self-hosted Big Data Infra | Large & unreliable storage | Single daily batch ingestion of +4,000 tables across the organization",
    inventionList: [
      {
        description: "Created data pipelines to AWS EMR & AWS S3",
      },
      {
        description: "Orchestration system for data ingestion and optimization",
      },
      {
        description: "Real-time streaming infrastructure setup",
      },
      {
        description: "Data visualization for real-time business dashboarding",
      },
    ],
    numberList: [
      {
        num: "45%",
        description: "reduction in data storage cost",
      },

      {
        num: "2hr",
        description: "data landing time, down from 24 hours",
      },

      {
        num: "3x",
        description: "improvement in ops team efficiency with RT dashboards",
      },

      {
        num: "92%",
        description: "reduction in data onboarding time, from 14 days to 1 day",
      },
    ],
  },

  {
    company: "Sats",
    imgBackground: "light-red",
    img: "images/customer-page/logos/sats_web.webp",
    title: "Multi-objective optimization models",
    tag: "MACHINE LEARNING",
    description:
      "SATS wanted to optimize their ground operations, especially around passenger connections, and convert it from gut-based manual decisions to algorithmically optimized decision making.",
    inventionList: [
      {
        description:
          "Optimized ground operations for one of Asia’s busiest airports with real-time, AI-driven decisioning",
      },
      {
        description:
          "Developed and deployed multi-objective optimization models for reducing passenger misconnections while improving on-time performance",
      },
    ],
    numberList: [
      {
        num: "",
        description:
          "Significant estimated improvement in passenger misconnection rate",
      },

      {
        num: "",
        description:
          "Hundreds of thousands of dollars in estimated savings per quarter, based on simulations",
      },

      {
        num: "",
        description:
          "Significant estimated reduction in average delay per flight",
      },
    ],
  },
];

const cardData = [
  {
    imgBackground: "light-red",
    img_web: "images/customer-page/logos/dbs_web.png",
    img_mob: "images/customer-page/logos/dbs_mob.png",
    title: "Real-time Campaign Management",
    tag: "DIGITAL EXPERIENCE",
    description:
      "DBS needed a real-time, campaign personalization engine that ingests disparate real-time & static data, including analytical models, with ability to define campaign rules, target audience & marketing channels.",
    inventionList: [
      {
        description:
          "Ingest & enrich real-time transaction & location data, and static profile, merchant, card etc.data from multiple sources",
      },
      {
        description:
          "Enable business users to configure real-time trigger-based campaigns via a user-friendly interface, across 3 SEA markets",
      },
      {
        description:
          "Trigger real-time campaigns that are personalized and contextualised to the customer",
      },
    ],
    numberList: [
      {
        num: "",
        description:
          "Millions of transactions processed per day across multiple data sources",
      },
      {
        num: "",
        description:
          "Multiple Real-time & static data sources across 3 countries",
      },
      {
        num: "",
        description: "Millions of real-time campaigns triggered per day",
      },
      {
        num: "",
        description:
          "Hundreds of real-time campaigns configured across multiple countries",
      },
    ],
  },
  {
    imgBackground: "light-green",
    img_web: "images/customer-page/logos/humana_web.png",
    img_mob: "images/customer-page/logos/humana_web.png",
    title: "End-to-end marketing analysis",
    tag: "MACHINE LEARNING",
    description:
      "Humana suffered from a scattered view of campaigns across multiple marketing & CRM sources, lack of data glossary, dependence on technical team for data and delayed business decisions",
    inventionList: [
      {
        description:
          "End-to-end service for extracting marketing data from multiple sources",
      },
      {
        description:
          "Harmonized data; maintained glossary to ensure trust in data",
      },
      {
        description:
          "Consolidated view for marketing data & analytics from multiple sources",
      },
      {
        description:
          "AI/ML Powered Actionable Insights for marketers, including Multi-channel attribution & Market-Mix modeling",
      },
    ],
    numberList: [
      {
        num: "",
        description:
          "Hundreds of queries per month on Qbo with 15 active users",
      },
      {
        num: "",
        description: "Ad-hoc report creation time, down from weeks to hours",
      },
      {
        num: "",
        description: "Over a hundred ad-hoc dashboards built within 15 months",
      },
      {
        num: "",
        description:
          "Time for campaign re-configuration, down from weeks to days",
      },
    ],
  },
  {
    imgBackground: "light-green",
    img_web: "images/customer-page/logos/etisalat_web.png",
    img_mob: "images/customer-page/logos/etisalat_mob.png",
    title: "Real-time Campaign Management",
    tag: "MACHINE LEARNING",
    description:
      "Etisalat faced challenges in real-time processing CDR data, inability to send contextualised real-time offers and lack of agility in adjusting campaign conditions",
    inventionList: [
      {
        description:
          "Deploy streaming data platform  to ingest & enrich streaming data like CDRs, transactions, etc.",
      },
      {
        description:
          "Trigger campaigns in real-time that are personalized and contextualised",
      },
      {
        description:
          "Targeted marketing at all stages of the customer life cycle",
      },
    ],
    numberList: [
      {
        num: "",
        description:
          "Billions of transactions being processed in real-time per day",
      },

      {
        num: "",
        description: "Customer base of millions monitored in real-time",
      },
    ],
  },
];

function Customer() {
  const [open, setOpen] = useState(false);
  const [modelIndex, setModelIndex] = useState(0);
  const [selectedFilters, setSelectedFilters] = useState([
    false,
    false,
    false,
    false,
  ]);
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const [filteredSmallCardData, setFsilteredSmallCardData] =
    useState(smallCardData);

  const tagIndexByName = {
    "Generative AI": 0,
    "MACHINE LEARNING": 1,
    "Data Platorms": 2,
    "DIGITAL EXPERIENCE": 3,
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleModal = (index) => {
    setOpen(!open);

    setModelIndex(index % 10);
  };

  const closeModel = () => {
    setOpen(false);
  };

  const setSelectedFilterState = (e, index, value) => {
    e.stopPropagation();
    selectedFilters[index] = value;
    let arr = [];
    for (let i = 0; i < 4; i++) {
      if (i === index) {
        arr.push(value);
      } else {
        arr.push(selectedFilters[i]);
      }
    }

    setSelectedFilters(arr);

    let copyOfFilteredCard = smallCardData.filter(
      (item) => selectedFilters[tagIndexByName[item.tag]]
    );

    if (copyOfFilteredCard.length === 0) {
      copyOfFilteredCard = smallCardData;
    }

    console.log("copyOfFilteredCard ", copyOfFilteredCard);
    setFsilteredSmallCardData(copyOfFilteredCard);
  };

  const Layout = ({ children, backgroundColor }) => {
    return screenSize <= 768 ? (
      <MobileLayout backgroundColor={backgroundColor}>{children}</MobileLayout>
    ) : (
      <DesktopLayout backgroundColor={backgroundColor}>
        {children}
      </DesktopLayout>
    );
  };

  const cardList = cardData.map((item, index) => {
    return (
      <Card
        key={index}
        imgBackground={item.imgBackground}
        img_web={item.img_web}
        img_mob={item.img_mob}
        title={item.title}
        description={item.description}
        tag={item.tag}
        inventionList={item.inventionList}
        numberList={item.numberList}
        index={index}
      />
    );
  });

  const smallCardList = [];

  const filteredDataLength = filteredSmallCardData.length;

  for (let index = 1; index + 1 < filteredSmallCardData.length; index += 2) {
    console.log("Index", index);
    const rowNumber = Math.floor(index / 2);
    let row;

    const item1 = filteredSmallCardData[index];
    const item2 = filteredSmallCardData[index + 1];

    console.log("item1", item1);
    console.log("item2", item2);

    if (rowNumber % 2 === 0) {
      row = (
        <div className="md:flex justify-between gap-[22px]">
          <div className="w-full md:w-[52%]">
            <SmallCard
              key={index}
              title={item1.title}
              heading={item1.heading}
              img={item1.img_web}
              description={item1.description}
              tag={item1.tag}
              toggleModal={toggleModal}
              index={item1.modalIndex}
            />
          </div>
          <div className="w-full mt-8 md:mt-0 md:w-[48%] ">
            <SmallCard
              key={index}
              title={item2.title}
              heading={item2.heading}
              img={item2.img_web}
              description={item2.description}
              tag={item2.tag}
              toggleModal={toggleModal}
              index={item2.modalIndex}
            />
          </div>
        </div>
      );
    } else {
      row = (
        <div className="md:flex justify-between gap-[22px]">
          <div className="w-full md:w-[48%]">
            <SmallCard
              key={index}
              title={item1.title}
              heading={item1.heading}
              img={item1.img_web}
              description={item1.description}
              tag={item1.tag}
              toggleModal={toggleModal}
              index={item1.modalIndex}
            />
          </div>
          <div className="w-full mt-8 md:mt-0 md:w-[52%]">
            <SmallCard
              key={index}
              title={item2.title}
              heading={item2.heading}
              img={item2.img_web}
              description={item2.description}
              tag={item2.tag}
              toggleModal={toggleModal}
              index={item2.modalIndex}
            />
          </div>
        </div>
      );
    }

    smallCardList.push(row);
  }

  if (filteredDataLength % 2 === 0) {
    const lastItem = filteredSmallCardData.at(-1);
    smallCardList.push(
      <div className="w-full md:w-[50%]">
        <SmallCard
          title={lastItem.title}
          heading={lastItem.heading}
          img={lastItem.img_web}
          description={lastItem.description}
          tag={lastItem.tag}
          toggleModal={toggleModal}
          index={lastItem.modalIndex}
        />
      </div>
    );
  }

  return (
    <div>
      {/* Hero Container */}
      <div className="customer-background">
        <Header />

        <div className="mt-[252px] ml-[6%] mb-[180px]">
          <div className="text-[49px] md:text-[52px] text-[#070D4A] tracking-[-1.8px] leading-[100%] mt-[16px] md:w-[46%]  RoobertMedium">
            There is no compression algorithm for experience 
          </div>
        </div>
        <div className="bg-[#3E3E3E] h-[77px] hidden md:flex">
          <div className="w-[16%] text-[#FFF] pl-[5%] text-[12px] flex items-center">
            Customers, our team has worked with
          </div>

          <Marquee velocity={20}>
            <img
              src="/images/customer-page/company.png"
              alt="img1"
              className="h-[77px]"
            />
            <img
              src="/images/customer-page/company.png"
              alt="img1"
              className="h-[77px]"
            />
          </Marquee>
          <div className="w-[13%]">&nbsp;</div>
        </div>

        <div className="bg-[#3E3E3E] h-[77px] md:hidden">
          <div className="ml-[10px] text-[#FFF] text-[12px] pt-[10px]">
            Customers, our team has worked with
          </div>
          <div className="flex flex-row ">
            <Marquee velocity={5}>
              <img
                src="/images/customer-page/company_mob.png"
                alt="band1"
                className="h-[50px]"
              />
              <img
                src="/images/customer-page/company_mob.png"
                alt="band1"
                className="h-[50px]"
              />
            </Marquee>
          </div>
        </div>
      </div>
      {/* Hero Container */}

      <Layout>
        <div>
          <div>&nbsp;</div>
          <div className="ml-[10%] mt-[40px] md:mt-[66px] RoobertMedium text-[35px] md:text-[48px] text-[#272941]">
            Customers, our team has worked with
          </div>
          <div className="mt-[64px] md:mt-[146px] mb-[16px] md:mb-[146px] flex flex-col gap-[64px] md:gap-[52px] w-[87%] md:w-[80%] mx-auto">
            {cardList}
          </div>

          <div className="mx-auto w-[87%] md:w-[80%]">
            <div className="md:flex md:gap-[22px]">
              <div className="pt-[48px] pb-[10px] md:w-[48%]">
                <div className="text-[48px] leading-[100%] text-[#000] RoobertMedium w-[50%] ">
                  Explore more:
                </div>
                <div className=" uppercase mt-[24px] mb-[24px] text-[#000] RoobertRegular text-[13px] tracking-[0.845px]">
                  Filter by expertise{" "}
                </div>
                <div className="hidden md:block text-[13px] md:w-full">
                  <div className="flex gap-[18px]">
                    <FilteredButton
                      selected={selectedFilters[3]}
                      text="Digital Experience"
                      color={"light-orange"}
                      index={3}
                      setSelectedFilterState={setSelectedFilterState}
                    />

                    <FilteredButton
                      selected={selectedFilters[0]}
                      text="Generative AI"
                      color={"light-blue"}
                      index={0}
                      setSelectedFilterState={setSelectedFilterState}
                    />
                  </div>
                  <div className="flex gap-[18px] mt-[12px]">
                    <FilteredButton
                      selected={selectedFilters[2]}
                      text="Data Platorms"
                      color={"green"}
                      index={2}
                      setSelectedFilterState={setSelectedFilterState}
                    />
                    <FilteredButton
                      selected={selectedFilters[1]}
                      text="MACHINE LEARNING"
                      color={"light-violet"}
                      index={1}
                      setSelectedFilterState={setSelectedFilterState}
                    />
                  </div>
                </div>
                <div className=" md:hidden text-[13px] md:w-full">
                  <div className="flex gap-[18px]">
                    <FilteredButton
                      selected={selectedFilters[3]}
                      text="Digital Experience"
                      color={"light-orange"}
                      index={3}
                      setSelectedFilterState={setSelectedFilterState}
                    />
                  </div>
                  <div className="flex gap-[18px] mt-[12px]">
                    <FilteredButton
                      selected={selectedFilters[0]}
                      text="Generative AI"
                      color={"light-blue"}
                      index={0}
                      setSelectedFilterState={setSelectedFilterState}
                    />
                    <FilteredButton
                      selected={selectedFilters[2]}
                      text="Data Platorms"
                      color={"green"}
                      index={2}
                      setSelectedFilterState={setSelectedFilterState}
                    />
                  </div>
                  <div className="flex gap-[18px] mt-[12px]">
                    <FilteredButton
                      selected={selectedFilters[1]}
                      text="MACHINE LEARNING"
                      color={"light-violet"}
                      index={1}
                      setSelectedFilterState={setSelectedFilterState}
                    />
                  </div>
                </div>
              </div>
              {filteredSmallCardData.length > 0 ? (
                <div className="w-full mt-8 md:mt-0 md:w-[52%] ">
                  <SmallCard
                    title={filteredSmallCardData[0].title}
                    heading={filteredSmallCardData[0].heading}
                    img={filteredSmallCardData[0].img_web}
                    description={filteredSmallCardData[0].description}
                    tag={filteredSmallCardData[0].tag}
                    toggleModal={toggleModal}
                    index={filteredSmallCardData[0].modalIndex}
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </Layout>
      <Layout>
        <div>
          {" "}
          <div>&nbsp;</div>
          <div className="mx-auto w-[87%] md:w-[80%] mt-[8px] mb-[64px] md:mb-[126px]">
            <div className="grid grid-cols-1 gap-y-[32px] gap-x-[22px]">
              {smallCardList}
            </div>
          </div>
          <div>&nbsp;</div>
        </div>
      </Layout>

      <Layout backgroundColor="black">
        <Contact
          title={
            "See how Generative AI can revolutionize your business, and improve customer engagement and employee productivity"
          }
          type="service"
        />
        <div className="">
          {/* <div>&nbsp;</div>
          <div className="bg-white px-[36px] pt-[60px] pb-[32px] w-[87%] md:w-[80%] mx-auto mt-[136px] mb-[136px] rounded-[16px] border-[1px] border-[#E7E7E7]">
            <div className="md:px-[35px]">
              <div className=" text-[35px] md:text-[48px] font-medium text-[#000] RoobertMedium ">
                Key Partnerships
              </div>
              <div className="text-[18px] mt-[14px] md:w-[40%] text-[#000] RoobertRegular ">
                Leveraging our partnerships with leading cloud providers like
                AWS, Snowflake, and Databricks, we offer robust cloud
                infrastructure design, implementation, and optimization
                services.
              </div>
            </div>
            <div className="w-[95%] mx-auto hidden md:block pt-[71px] pb-[43px] px-[11px]">
              <img
                src="images/story/company-frame.png"
                alt="compnay frame"
                className="w-full"
              />
            </div>
            <div className="mx-auto pt-[71px] pb-[43px] md:hidden">
              <img
                src="images/story/company_frame_mob.png"
                alt="compnay frame"
                className="w-full"
              />
            </div>
          </div>
          <div>&nbsp;</div> */}
          <Footer />
        </div>
      </Layout>
      {open && (
        <CustomerModal
          element={
            <CardBody
              imgBackground={modalData[modelIndex].imgBackground}
              img_web={modalData[modelIndex].img}
              img_mob={modalData[modelIndex].img}
              title={modalData[modelIndex].title}
              description={modalData[modelIndex].description}
              tag={modalData[modelIndex].tag}
              inventionList={modalData[modelIndex].inventionList}
              numberList={modalData[modelIndex].numberList}
              index={modelIndex}
            />
          }
          open={open}
          onClose={closeModel}
        />
      )}
    </div>
  );
}

export default Customer;
